<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Deal Order</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer/Deal ID"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mx-0 m-mt-2 me-3">
                    <button
                        class="btn btn-outline-yellow btn-filter px-3"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4 mb-2" v-show="isRole(managerRoles)">
                        <label class="fw-bold mb-1">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM specialist"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div
                        class="mb-2"
                        v-bind:class="{
                            'col-md-8': !isRole(managerRoles),
                            'col-md-4': isRole(managerRoles),
                        }"
                    >
                        <label class="fw-bold mb-1">Kategori</label>
                        <multiselect
                            v-model="category"
                            :options="categories"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kategori"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-filter">
                        <button
                            class="btn btn-outline-yellow px-5 me-3"
                            @click="clearFilter()"
                            :disabled="category == null && specialist == null"
                        >
                            Clear
                        </button>
                        <button
                            class="btn btn-green px-5"
                            :disabled="category == null && specialist == null"
                            @click="filterDate()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">1-{{ limit }}</span> Data dari
                    <span class="fw-bold">{{ list.total_data }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                            @change="getLists()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_list">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    <div class="d-flex align-items-center">
                                        Tanggal Deal Order

                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                class="dropdown-toggle ms-2"
                                                id="statusRefund"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="true"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="../assets/dashboard/sort.png"
                                                    width="9"
                                                    alt=""
                                                />
                                            </a>
                                            <ul
                                                class="dropdown-menu"
                                                aria-labelledby="statusRefund"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('asc')"
                                                    >
                                                        Terbaru
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('desc')"
                                                    >
                                                        Terlama
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th class="text-center" scope="col">Deal ID</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Kategori
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerRoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Estimasi Berat Barang
                                </th>
                                <th class="text-center" scope="col">Rute</th>
                                <th class="text-center" scope="col">
                                    Harga Pengiriman
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Driver
                                </th>
                                <th class="text-center" scope="col">
                                    Status Penjemputan
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="
                                        tier == 'platinum' ||
                                        tier == 'enterprise'
                                    "
                                >
                                    Request Jemput
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in list.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{ item.created_at }}
                                </td>
                                <td>
                                    {{ item.receipt ? item.receipt : "-" }}
                                </td>
                                <td class="capitalize">
                                    {{ item.customer_name }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 capitalize"
                                        v-bind:class="{
                                            'blue-cro':
                                                item.category == 'new_order',
                                            'green-cro':
                                                item.category == 'repeat_order',
                                        }"
                                    >
                                        {{ item.category.split("_").join(" ") }}
                                    </span>
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(managerRoles)"
                                >
                                    {{ item.specialist }}
                                </td>
                                <td class="text-center">
                                    {{ item.est_weight }} Kg
                                </td>
                                <td>
                                    {{ item.route }}
                                </td>
                                <td>
                                    <span class="pull-right">
                                        {{ currency(item.price) }}
                                    </span>
                                </td>
                                <td>
                                    {{
                                        item.driver_name
                                            ? item.driver_name
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 pb-0 capitalize blue-cro rounded-pill"
                                    >
                                        {{
                                            item.status_label
                                                ? item.status_label
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td
                                    class="text-center"
                                    v-if="
                                        tier == 'platinum' ||
                                        tier == 'enterprise'
                                    "
                                >
                                    <a
                                        href="javascript:void(0)"
                                        @click="openModalRequest(item)"
                                        v-if="item.status == false"
                                    >
                                        <img
                                            src="../assets/request.png"
                                            width="20"
                                            alt=""
                                        />
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        class="cursor-drop"
                                        v-else
                                    >
                                        <img
                                            src="../assets/request-gray.png"
                                            width="20"
                                            alt=""
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="list.list_data.length == 0" class="text-center mt-5">
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar Deal Order
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <b-pagination-nav
                            v-if="list.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="totalPage"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage()"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalRequest"></div>
            <div class="modal-body-chat sm" v-if="modalRequest">
                <div class="">
                    <h4 class="mb-0 fw-bold">Request Penjemputan Orderan</h4>
                    <div class="mt-3">
                        <label class="fw-bold mb-1">
                            Unit Armada
                            <span class="text-danger">*</span>
                        </label>
                        <multiselect
                            v-model="fleet"
                            :options="fleets"
                            label="name"
                            track-by="code"
                            placeholder="Pilih unit armada"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="mt-2">
                        <label class="fw-bold mb-1">
                            Catatan untuk Driver
                            <span class="text-danger">*</span>
                        </label>
                        <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            placeholder="Tulis catatan"
                            v-model="note"
                        ></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalRequest = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="
                                is_request || fleet == null || note == ''
                            "
                            @click="storeRequest()"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_request"
                            ></span>
                            Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Request Penjemputan Berhasil
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalSuccess = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    name: "DealOrder",
    props: ["roles"],
    data() {
        return {
            moment: moment,
            search: "",
            isFilter: false,
            specialist: null,
            specialists: [],
            category: null,
            categories: [
                {
                    name: "New Order",
                    id: "new",
                },
                {
                    name: "Repeat Order",
                    id: "repeat",
                },
            ],
            managerRoles: ["crm-manager"],
            list: {
                list_data: [],
                total_data: 0,
            },
            currentPage: 0,
            totalPage: 10,
            limit: 10,
            is_load_list: false,
            sort_at: "asc",
            modalRequest: false,
            modalSuccess: false,
            fleet: null,
            fleets: [],
            is_request: false,
            requst_id: null,
            note: "",
            tier: localStorage.getItem("tier"),
        };
    },
    created() {
        this.getSpecialists();
        this.getLists();
        this.getFleets();
    },
    methods: {
        filterDate() {
            this.getLists();
        },
        clearFilter() {
            this.specialist = null;
            this.category = null;
            this.getLists();
        },
        changePage() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.specialist) {
                payload.specialist_id = this.specialist?.id;
            }
            if (this.sort_at) {
                payload.sort_at = this.sort_at;
            }
            if (this.category) {
                payload.category = this.category?.id;
            }
            this.is_load_list = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/result/deal/lists`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.list = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.is_load_list = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_list = false;
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sortBy(value) {
            this.sort_at = value;
            this.getLists();
        },
        getFleets() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.fleets = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        openModalRequest(item) {
            this.requst_id = item.id;
            this.modalRequest = true;
            this.note = "";
            this.fleet = null;
        },
        storeRequest() {
            this.is_request = true;
            var data = {
                result_id: this.requst_id,
                fleet: this.fleet?.code,
                note: this.note,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/result/deal/pickup`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalRequest = false;
                    this.modalSuccess = true;
                    this.is_request = false;
                    this.getLists();
                })
                .catch((err) => {
                    this.$toast.error(err.response.data.message, {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                    this.is_request = false;
                    this.modalRequest = false;
                });
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
    },
};
</script>

<style scoped>
.w-search {
    width: 280px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.mt-filter {
    margin-top: 25px;
}
</style>
