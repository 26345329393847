<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a href="/walk-in" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-3">
            <h4 class="mb-0 fw-bold">Data Customer</h4>
            <div class="row mt-4">
                <div class="col-md-6">
                    <label class="fw-bold mb-1">
                        Nama Customer
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        v-model="req.sender_name"
                    />
                </div>
                <div class="col-md-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            placeholder="Masukkan nomor pengirim"
                            @input="validatePhoneSender"
                            v-model="req.sender_phone"
                        />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="originRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="destRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari kota/kabupaten tujuan"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h4 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h4>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (Kg3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <money
                                    v-model="item.item"
                                    v-bind="format_number"
                                    class="form-control w-koli"
                                    placeholder="Koli"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control w-desc"
                                    placeholder="Deskripsi"
                                    v-model="item.description"
                                />
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.weight"
                                    v-bind="format_number"
                                    class="form-control"
                                    v-bind:class="{
                                        green: item.weight > item.volume,
                                    }"
                                    placeholder="Berat"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.length"
                                    v-bind="format_number"
                                    class="form-control"
                                    placeholder="P"
                                    @input="calculateResult()"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.width"
                                    v-bind="format_number"
                                    class="form-control"
                                    placeholder="L"
                                    @input="calculateResult()"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.height"
                                    v-bind="format_number"
                                    class="form-control"
                                    placeholder="T"
                                    @input="calculateResult()"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.volume"
                                    v-bind="format_number"
                                    class="form-control"
                                    v-bind:class="{
                                        green: item.volume > item.weight,
                                    }"
                                    disabled
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <money
                                        v-model="item.cubic"
                                        v-bind="format_number"
                                        class="form-control me-2"
                                        disabled
                                    >
                                    </money>
                                    <a
                                        href="javascript:void(0)"
                                        @click="showModalDeleteItem(index)"
                                    >
                                        <img
                                            src="../assets/trash-yellow.png"
                                            width="16"
                                            lt=""
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-300">
                                    <label class="fw-medium mb-1">
                                        Perlindungan Barang
                                    </label>
                                    <a
                                        href="javascript:void(0)"
                                        class="dropdown-toggle text-gray-dashboard w-"
                                        :id="`handling` + index"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="true"
                                        aria-expanded="false"
                                    >
                                        <div class="box radius-select">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div class="scroll-select">
                                                    <template
                                                        v-if="
                                                            item.handling
                                                                .length == 0
                                                        "
                                                    >
                                                        Pilih perlindungan
                                                        barang
                                                    </template>
                                                    <template v-else>
                                                        <span
                                                            v-for="(
                                                                handling_text,
                                                                index
                                                            ) in item.handling"
                                                            :key="index"
                                                            class="capitalize"
                                                        >
                                                            {{
                                                                handling_text
                                                                    .split("_")
                                                                    .join(" ")
                                                            }}<span
                                                                v-if="
                                                                    index !==
                                                                    item
                                                                        .handling
                                                                        .length -
                                                                        1
                                                                "
                                                                >,</span
                                                            >
                                                        </span>
                                                    </template>
                                                </div>
                                                <div class="ms-auto">
                                                    <img
                                                        src="../assets/angle-down.png"
                                                        class="ms-1"
                                                        width="14"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <ul
                                        class="dropdown-menu"
                                        :aria-labelledby="`handling` + index"
                                    >
                                        <li
                                            v-for="(
                                                handling, index
                                            ) in handlings"
                                            :key="index"
                                            class="pt-1 pb-1"
                                            v-bind:class="{
                                                'border-bottom':
                                                    index !==
                                                    handlings.length - 1,
                                            }"
                                        >
                                            <div
                                                class="form-check form-check-inline cursor-pointer"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :id="handling.id"
                                                    :value="handling.value"
                                                    v-model="item.handling"
                                                />
                                                <label class="form-check-label">
                                                    {{ handling.name }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <button class="btn btn-green px-4">
                    <div class="d-flex align-items-center" @click="addItems()">
                        <i class="pi pi-plus me-1"></i>
                        Tambah Barang
                    </div>
                </button>
            </div>
            <hr class="mt-4" />
            <h4 class="fw-bold mb-0">Hasil</h4>
            <div class="table-responsive-custom">
                <table class="result table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Kubikasi (Kg3)
                            </th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalWeight) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalVolume) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalCubic) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{ decimalFormat(chargeWeight) }}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="pt-4">
                                <button
                                    class="btn btn-primary w-100"
                                    @click="modalSuccessConfirmation = true"
                                    :disabled="
                                        totalWeight == 0 ||
                                        chargeWeight == 0 ||
                                        items[0].description == ''
                                    "
                                >
                                    Simpan
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data Ini Akan Dihapus</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ukur-timbang
                        barang ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Pesanan Akan Dibuat</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Pesanan Berhasil Dibuat</h4>
                </div>
                <div class="mt-4">
                    <a href="/walk-in" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";
import { Money } from "v-money";

export default {
    components: {
        Multiselect,
        Money,
    },
    name: "OrderCashierCreate",
    data() {
        return {
            regencies: [],
            originRegency: null,
            destRegency: null,
            req: {
                sender_name: "",
                sender_phone: "",
            },
            handlings: [
                {
                    name: "Karung Kecil",
                    value: "karung_kecil",
                    id: 1,
                },
                {
                    name: "Karung Sedang",
                    value: "karung_besar",
                    id: 2,
                },
                {
                    name: "Plastik",
                    value: "plastik",
                    id: 3,
                },
                {
                    name: "Bubble Wrap",
                    value: "bubble_wrap",
                    id: 4,
                },
                {
                    name: "Kayu",
                    value: "kayu",
                    id: 5,
                },
            ],
            items: [
                {
                    item: 0,
                    description: "",
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    cubic: 0,
                    handling: [],
                },
            ],
            format_number: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                allowNegative: false,
            },
            delete_value: null,
            modalDelete: false,
            is_save: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
        };
    },
    created() {
        this.getRegency();
    },
    directives: {
        autofocus: {
            inserted: (el) => {
                el.focus();
            },
        },
    },
    computed: {
        totalWeight() {
            return this.items.reduce(
                (total, item) => total + item.item * Math.round(item.weight),
                0
            );
        },
        totalVolume() {
            return this.items.reduce(
                (total, item) => total + item.item * Math.round(item.volume),
                0
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) => total + item.item * Math.round(item.cubic),
                0
            );
        },
        chargeWeight() {
            return this.items.reduce((total, item) => {
                const itemWeight = item.item * Math.round(item.weight);
                const itemVolume = item.item * Math.round(item.volume);
                return total + Math.max(itemWeight, itemVolume);
            }, 0);
        },
    },
    methods: {
        validatePhoneSender() {
            if (!this.req.sender_phone.startsWith("8")) {
                this.req.sender_phone =
                    "8" + this.req.sender_phone.replace(/[^0-9]/g, "");
            } else {
                this.req.sender_phone = this.req.sender_phone.replace(
                    /[^0-9]/g,
                    ""
                );
            }
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.receiver_regencies = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addItems() {
            this.items.push({
                item: 0,
                description: "",
                weight: 0,
                length: 0,
                width: 0,
                height: 0,
                volume: 0,
                cubic: 0,
                handling: [],
            });
        },
        showModalDeleteItem(index) {
            this.modalDelete = true;
            this.delete_value = index;
        },
        deleteItem() {
            this.items.splice(this.delete_value, 1);
            this.modalDelete = false;
        },
        calculateResult() {
            this.items.forEach((val) => {
                if (val.length !== 0 && val.width !== 0 && val.height !== 0) {
                    val.volume = (val.length * val.width * val.height) / 4000;
                    val.cubic = (val.length * val.width * val.height) / 1000000;
                } else {
                    val.volume = 0;
                    val.cubic = 0;
                }
            });
        },
        storeData() {
            this.is_save = true;
            const itemsData = this.items.map((item) => ({
                qty: item.item,
                description: item.description,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                weight_volume: item.volume,
                cubic: item.cubic,
                protection: item.handling.join(","),
            }));
            var data = {
                sender_name: this.req.sender_name,
                sender_phone: this.req.sender_phone,
                origin_regency_id: this.originRegency?.id,
                destination_regency_id: this.destRegency?.id,
                items: itemsData,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/create/walkin`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccess = true;
                    this.modalSuccessConfirmation = false;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    this.modalSuccessConfirmation = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.table > :not(caption) > * > * {
    border: unset;
}
.table-bordered > :not(caption) > * {
    border: unset;
}
.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
.form-edit {
    border: unset !important;
    text-align: right;
    padding: 0px;
    font-weight: 600 !important;
    color: #000 !important;
    border-radius: unset !important;
}
.form-edit.yellow {
    color: #ff8600 !important;
}
</style>
