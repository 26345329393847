import { render, staticRenderFns } from "./OrderCashier.vue?vue&type=template&id=bf840cc4&scoped=true"
import script from "./OrderCashier.vue?vue&type=script&lang=js"
export * from "./OrderCashier.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf840cc4",
  null
  
)

export default component.exports