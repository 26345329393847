<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <div class="fw-bold">
            <a href="/data/leads" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Detail Data Leads</h3>
                <div class="ms-auto">
                    <a :href="'/data/leads/edit/' + this.$route.query.lead_id">
                        <i class="pi pi-pencil me-1"></i>
                        Edit
                    </a>
                </div>
            </div>
            <div class="mt-4">
                <label class="fw-bold">Tags</label>
                <div class="mt-2">
                    <template v-if="detailLeads.tags != null">
                        <template v-for="(tag, index) in detailLeads.tags">
                            <span class="tag gray-new radius" :key="index">{{
                                tag
                            }}</span>
                        </template>
                    </template>
                    <template v-else> - </template>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Nama Customer</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.name ? detailLeads?.name : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Email</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.email
                                        ? detailLeads?.email
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Jabatan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.position
                                        ? detailLeads?.position
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Sumber Info</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.data_source
                                        ? detailLeads?.data_source
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Nomor Handphone</td>
                            <td class="w-30">:</td>
                            <td>
                                +{{
                                    detailLeads?.phone
                                        ? detailLeads?.phone
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Perusahaan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.company_name
                                        ? detailLeads?.company_name
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">No. Telp Perusahaan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.company_phone
                                        ? detailLeads?.company_phone
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Catatan</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.note ? detailLeads?.note : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="">
                            <td class="ps-0 w-80">Alamat</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailLeads?.address
                                        ? detailLeads?.address
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-if="$route.name == 'crm-prospect-detail'">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Detail Aktivitas Follow Up</h3>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Kategori</td>
                            <td class="w-30">:</td>
                            <td>
                                <span
                                    class="tag me-0"
                                    v-bind:class="{
                                        'yellow-cro':
                                            detailFollowup.category ==
                                            'New Customer',
                                        'blue-cro':
                                            detailFollowup.category ==
                                            'New Order',
                                        'green-cro':
                                            detailFollowup.category ==
                                            'Repeat Order',
                                    }"
                                >
                                    {{
                                        detailFollowup.category
                                            ? detailFollowup.category
                                            : "-"
                                    }}
                                </span>
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80">Aktivitas Terakhir</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailFollowup.activity
                                        ? detailFollowup.activity
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-6">
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-200">Tgl. Order Terakhir</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailFollowup.last_order
                                        ? detailFollowup.last_order
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom" v-show="isRole(managerRoles)">
                            <td class="ps-0 w-200">CRM Specialist</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailFollowup.specialist
                                        ? detailFollowup.specialist
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-12">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="">
                            <td class="ps-0 w-80">Prioritas</td>
                            <td class="w-30">:</td>
                            <td>
                                {{
                                    detailFollowup.priority
                                        ? detailFollowup.priority
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Riwayat Aktivitas Follow Up</h3>
                <div class="ms-auto">
                    <template v-if="activities.length == 0">
                        <a
                            :href="
                                `/data/leads/prospect/` + $route.query.lead_id
                            "
                            class="btn btn-primary px-4 btn-with-search"
                        >
                            <i class="pi pi-plus me-1"></i>
                            Tambah Aktivitas
                        </a>
                    </template>
                    <template v-else>
                        <button
                            class="btn btn-primary px-4 btn-with-search"
                            @click="modalActivity = true"
                        >
                            <i class="pi pi-plus me-1"></i>
                            Tambah Aktivitas
                        </button>
                    </template>
                </div>
            </div>
            <div class="mt-4" v-if="is_loading_activitiy">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Tanggal Follow Up
                                </th>
                                <th class="text-center" scope="col">
                                    Aktivitas
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerRoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">
                                    Deskripsi Aktivitas
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in activities"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{
                                        item.followup_date
                                            ? item.followup_date
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{ item.activity ? item.activity : "-" }}
                                </td>
                                <td v-show="isRole(managerRoles)">
                                    {{
                                        item.specialist ? item.specialist : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.description
                                            ? item.description
                                            : "-"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-section"
                    v-if="activities.length == 0"
                >
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada riwayat aktivitas follow up
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Riwayat Prediksi</h3>
                <div class="ms-auto" v-if="predictions.length > 0">
                    <a
                        :href="`/prediction/edit/` + $route.query.followup_id"
                        class="btn btn-primary px-4 btn-with-search"
                    >
                        <i class="pi pi-pencil me-1"></i>
                        Edit Prediksi
                    </a>
                </div>
            </div>
            <div class="mt-4" v-if="is_loading_activitiy">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">Tanggal</th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">Rute</th>
                                <th class="text-center" scope="col">Berat</th>
                                <th class="text-center" scope="col">Harga</th>
                                <th class="text-center" scope="col">
                                    Deskripsi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in predictions"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{
                                        item.created_at ? item.created_at : "-"
                                    }}
                                </td>
                                <td class="capitalize">
                                    {{
                                        item.service
                                            ? item.service.split("_").join(" ")
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{ item.origin ? item.origin : "-" }} -
                                    {{ item.dest ? item.dest : "-" }}
                                </td>
                                <td>{{ item.weight ? item.weight : "-" }}Kg</td>
                                <td>
                                    {{ currency(item.price ? item.price : 0) }}
                                </td>
                                <td>
                                    {{
                                        item.description
                                            ? item.description
                                            : "-"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-section"
                    v-if="predictions.length == 0"
                >
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada riwayat prediksi
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Hasil Follow Up</h3>
            </div>
            <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                <li class="nav-item w-50" role="presentation">
                    <button
                        class="nav-link dashboard left w-100 active"
                        id="pills-deal-tab"
                        @click="getTabValue('deal')"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-deal"
                        type="button"
                        role="tab"
                        aria-controls="pills-deal"
                        aria-selected="true"
                    >
                        Deal
                    </button>
                </li>
                <li class="nav-item w-50" role="presentation">
                    <button
                        class="nav-link dashboard right w-100"
                        id="pills-lost-tab"
                        @click="getTabValue('lost')"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-lost"
                        type="button"
                        role="tab"
                        aria-controls="pills-lost"
                        aria-selected="false"
                    >
                        Lost
                    </button>
                </li>
            </ul>
            <div class="mt-3" v-if="is_loading_followup_results">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">Tanggal</th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="typeValue === 'deal'"
                                >
                                    Resi
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="typeValue === 'deal'"
                                >
                                    Rute
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-if="typeValue === 'deal'"
                                >
                                    Harga
                                </th>
                                <th class="text-center" scope="col">
                                    Deskripsi
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerRoles)"
                                >
                                    {{
                                        typeValue === "deal"
                                            ? "CRM Specialist"
                                            : "Marketing"
                                    }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in followup_results"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{ item.created_at }}
                                </td>
                                <td v-if="typeValue === 'deal'">
                                    {{ item.receipt }}
                                </td>
                                <td v-if="typeValue === 'deal'">
                                    {{ item.route }}
                                </td>
                                <td
                                    class="text-center"
                                    v-if="typeValue === 'deal'"
                                >
                                    <span class="pull-right">
                                        {{ currency(item.price) }}
                                    </span>
                                </td>
                                <td>{{ item.description }}</td>
                                <td v-show="isRole(managerRoles)">
                                    {{ item.specialist }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-section"
                    v-if="followup_results.length == 0"
                >
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada Hasil Follow Up
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-if="life_time.list_data.length != 0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Customer Life Time Value</h3>
                <h3 class="ms-auto text-green">
                    <template v-if="life_time.total == 0"> Rp0 </template>
                    <template v-else>
                        {{ currency(life_time.total) }}
                    </template>
                </h3>
            </div>
            <div class="mt-4" v-if="is_loading_life_time">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">Tanggal</th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">Rute</th>
                                <th class="text-center" scope="col">Berat</th>
                                <th class="text-center" scope="col">Harga</th>
                                <th class="text-center" scope="col">
                                    Deskripsi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in life_time.list_data"
                                :key="index"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{
                                        item.created_at ? item.created_at : "-"
                                    }}
                                </td>
                                <td class="capitalize">
                                    {{ item.service.split("_").join(" ") }}
                                </td>
                                <td>
                                    {{ item.route }}
                                </td>
                                <td>{{ item.weight }}Kg</td>
                                <td>
                                    {{ currency(item.price) }}
                                </td>
                                <td>
                                    {{
                                        item.description
                                            ? item.description
                                            : "-"
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-section"
                    v-if="life_time.list_data.length == 0"
                >
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada Customer Life Time
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalActivity"></div>
            <div class="modal-body-chat sm" v-if="modalActivity">
                <div class="mt-2">
                    <h4 class="mb-0 fw-bold">Tambah Aktivitas Follow Up</h4>

                    <div class="row">
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-1">
                                Aktivitas
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <select
                                class="form-control form-select"
                                v-model="activity"
                            >
                                <option value="">Pilih aktivitas</option>
                                <option value="call">Telfon</option>
                                <option value="whatsapp">Chat WhatsApp</option>
                                <option value="other">Lainnya</option>
                            </select>
                        </div>
                        <div class="col-md-12 mt-3">
                            <label class="fw-bold mb-1">
                                Deskripsi Aktivitas
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <textarea
                                cols="30"
                                rows="4"
                                class="form-control"
                                placeholder="Tuliskan deskripsi kegiatan"
                                v-model="description_activity"
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalActivity = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeActifity()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="
                                is_store ||
                                active == '' ||
                                description_activity == ''
                            "
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_store"
                            ></span>
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessActivity"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessActivity">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Aktivitas Follow Up berhasil ditambahkan
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        class="btn btn-primary w-100 btn-lg"
                        @click="okModalActivity()"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import ListLoader from "../../components/ListLoader.vue";

export default {
    name: "customersLeadsDetail",
    components: {
        ListLoader,
    },
    props: ["roles"],
    data() {
        return {
            moment: moment,
            detailLeads: {},
            req: {},
            activities: [],
            is_loading_activitiy: false,
            predictions: [],
            is_loading_prediction: false,
            life_time: {
                list_data: [],
                total: 0,
            },
            is_loading_life_time: false,
            followup_results: [],
            is_loading_followup_results: false,
            modalActivity: false,
            activity: "",
            description_activity: "",
            is_store: false,
            modalSuccessActivity: false,
            typeValue: "deal",
            managerRoles: ["crm-manager"],
            detailFollowup: {},
            is_loading_data: false,
        };
    },
    created() {
        this.getDetailLeads();
        this.getActivities();
        this.getPredictions();
        this.getLifeTimes();
        this.getFollowupResults();
        this.getDetailActivity();
    },
    computed: {},
    methods: {
        getDetailLeads() {
            this.is_loading_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/leads/detail?id=${this.$route.query.lead_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.detailLeads = res.data?.data;
                    this.is_loading_data = false;
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        getDetailActivity() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/followup/detail?id=${this.$route.query.followup_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.detailFollowup = res.data?.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getActivities() {
            this.is_loading_activitiy = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/leads/activity/history?id=${this.$route.query.lead_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.is_loading_activitiy = false;
                    this.activities = res.data?.data;
                })
                .catch((err) => {
                    this.is_loading_activitiy = false;
                    console.log(err);
                });
        },
        getPredictions() {
            this.is_loading_prediction = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/history?id=${this.$route.query.lead_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.is_loading_prediction = false;
                    this.predictions = res.data?.data;
                })
                .catch((err) => {
                    this.is_loading_prediction = false;
                    console.log(err);
                });
        },
        getFollowupResults() {
            this.is_loading_followup_results = true;
            var data = {
                id: this.$route.query.lead_id,
                type: this.typeValue,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/leads/followup/results`,
                {
                    params: data,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.is_loading_followup_results = false;
                    this.followup_results = res.data?.data;
                })
                .catch((err) => {
                    this.is_loading_followup_results = false;
                    console.log(err);
                });
        },
        getLifeTimes() {
            this.is_loading_life_time = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/leads/customer/lifetime?id=${this.$route.query.lead_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.is_loading_life_time = false;
                    this.life_time = res.data?.data;
                })
                .catch((err) => {
                    this.is_loading_life_time = false;
                    console.log(err);
                });
        },
        storeActifity() {
            this.is_store = true;

            var data = {
                followup_id: this.$route.query.followup_id,
                activity: this.activity,
                description: this.description_activity,
                priority: "high",
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/followup/activity/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalActivity = false;
                    this.modalSuccessActivity = true;
                })
                .catch((err) => {
                    this.is_store = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        okModalActivity() {
            this.modalSuccessActivity = false;
            this.getActivities();
        },
        getTabValue(value) {
            this.typeValue = value;
            this.getFollowupResults();
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
    },
};
</script>

<style lang="scss" scoped>
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px;
}
tr {
    border-color: #e8e8e9;
    padding: 10px;
}
td {
    padding: 10px;
}
.w-200 {
    width: 200px;
}
.w-80 {
    width: 150px;
}
.upload__btn {
    color: #dee2e6;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    border: dashed;
    border-radius: 10px;
    width: 13vw;
    height: 150px;
    padding: 55px;
    position: relative;
}
.photo {
    border-radius: 10px;
    cursor: pointer;
    width: 13vw;
    height: 150px;
    object-fit: cover;
}
.btn-lg {
    width: 150px;
}
input[type="file"] {
    display: none;
}
.upload__btn p i {
    font-size: 30px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.center {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #3782e6 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.w-30 {
    width: 30px;
}
.border-top {
    border-color: #eee !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.nav-link.dashboard.active {
    background: #1291ff !important;
    border-radius: 50px;
}
.nav-link.dashboard {
    background: #cce5ff !important;
    border-radius: 50px;
    color: #006dcc;
}
</style>
