<template>
    <section class="bc-login">
        <notifications group="foo" position="top right" />
        <img src="../assets/map-login.png" class="map-login" alt="" />
        <img src="../assets/map-poin-login.png" class="map-poin-login" alt="" />
        <div class="container mt-5 position-relative">
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div class="box bc-gray-primary">
                        <center>
                            <img
                                src="../assets/logo.png"
                                width="150"
                                alt="logo klik"
                            />
                        </center>
                        <div class="size-18 fw-bold mt-3">Selamat Datang!</div>
                        <div>
                            Senang melihat Anda lagi! Silakan masuk untuk
                            melanjutkan pengelolaan data Anda.
                        </div>
                        <form @submit.prevent="loginAction()" class="mt-4">
                            <label class="mb-1 fw-bold">
                                Email/Username
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <input
                                type="email"
                                class="form-control form-login"
                                id="username"
                                placeholder="you@gmail.com"
                                v-model="username"
                                @input="validateEmail()"
                                autofocus
                            />
                            <div
                                class="text-yellow d-flex align-items-center mt-1"
                                v-if="emailError"
                            >
                                <img
                                    src="../assets/info-yellow.png"
                                    width="13"
                                    class="me-1"
                                    alt=""
                                />
                                {{ emailError }}
                            </div>
                            <div class="mt-3">
                                <div class="position-relative">
                                    <label class="mb-1 fw-bold">
                                        Password
                                        <span class="text-danger fw-bold"
                                            >*</span
                                        >
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control form-login"
                                        id="password"
                                        placeholder="•••••••••••••"
                                        v-model="password"
                                        @keyup.enter="loginAction()"
                                        v-if="showPassword"
                                    />
                                    <input
                                        type="password"
                                        class="form-control form-login"
                                        id="password"
                                        placeholder="•••••••••••••"
                                        v-model="password"
                                        @keyup.enter="loginAction()"
                                        v-else
                                    />
                                    <span
                                        class="search-icon cursor-pointer"
                                        @click="toggleShow"
                                    >
                                        <i
                                            class="fa"
                                            :class="{
                                                'fa-eye': showPassword,
                                                'fa-eye-slash': !showPassword,
                                            }"
                                        ></i>
                                    </span>
                                    <div
                                        class="text-yellow d-flex align-items-center mt-1"
                                        v-if="
                                            password.length < 6 &&
                                            password.length > 0
                                        "
                                    >
                                        <img
                                            src="../assets/info-yellow.png"
                                            width="13"
                                            class="me-1"
                                            alt=""
                                        />
                                        Password terdiri dari min. 6 karakter
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="pull-right-login mt-3 mb-3">
                            <u class="text-green">
                                <a
                                    href="/forgot/form"
                                    class="text-green size-16"
                                >
                                    Lupa Password
                                </a>
                            </u>
                        </div>
                        <div class="mt-4">
                            <button
                                class="btn btn-primary btn-lg w-100"
                                type="button"
                                disabled
                                v-if="is_login"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Masuk
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary btn-lg w-100"
                                :disabled="
                                    username == '' ||
                                    password == '' ||
                                    (password.length < 6 && password.length > 0)
                                "
                                @click="loginAction()"
                                v-else
                            >
                                Masuk
                            </button>
                        </div>
                        <center>
                            <div class="mt-3 text-gray-dashboard">
                                Powered By
                            </div>
                            <img
                                src="../assets/logo-tb-new.png"
                                width="150"
                                alt=""
                            />
                        </center>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalError"></div>
            <div class="modal-body-chat vsm" v-if="modalError">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="70" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Oops! Gagal Masuk</h4>
                    <div class="text-gray">
                        Email/Username tidak terdaftar atau Kata Sandi Anda
                        salah. Silakan coba lagi
                    </div>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="modalError = false"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Api from "../api/Api";

export default {
    props: "",
    name: "Login",
    data() {
        return {
            guard: "user",
            username: "",
            password: "",
            device_name: "Insomnia-Test-Client",
            jwt_token: "",
            user_id: "",
            showPassword: false,
            is_login: false,
            roles: [],
            roles_go: [],
            jwtToken: localStorage.getItem("token"),
            parsedJwt: null,
            modalError: false,
            emailError: "",
            tier: null,
        };
    },
    computed: {
        buttonLabel() {
            return this.showPassword ? "Hide" : "Show";
        },
    },
    created() {
        this.parseJwt(this.jwtToken);
    },
    methods: {
        validateEmail() {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(this.username)) {
                this.emailError = "Alamat email/username tidak valid";
            } else {
                this.emailError = "";
            }
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        loginAction() {
            var data = {
                email: this.username,
                password: this.password,
            };
            this.is_login = true;
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/auth/login`, data)
                .then((res) => {
                    console.log(res);
                    this.is_login = false;
                    this.jwt_token = res.data.data.token;
                    this.getTier();
                    this.getUserLogin();
                })
                .catch((err) => {
                    this.is_login = false;
                    console.log(err);
                    this.modalError = true;
                });
        },
        getUserLogin() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/auth/account`, {
                headers: {
                    Authorization: "Bearer " + this.jwt_token,
                },
            })
                .then((res) => {
                    this.user_id = res.data.data.hash;
                    if (this.user_id == "") {
                        ("");
                    } else {
                        this.$toast.success("Login Berhasil", {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                        this.roles = res.data.data.roles;
                        localStorage.setItem("token", this.jwt_token);
                        localStorage.setItem("roles", this.roles);
                        console.log("jwt", this.jwt_token);

                        setTimeout(() => {
                            // window.location.href = "/dashboard";
                            if (
                                this.tier != "silver" &&
                                (this.roles.includes("crm-manager") ||
                                    this.roles.includes("tracking"))
                            ) {
                                window.location.href = "/data/leads";
                            }
                            if (
                                this.tier == "silver" &&
                                (this.roles.includes("crm-manager") ||
                                    this.roles.includes("tracking"))
                            ) {
                                window.location.href = "/order/cashier";
                            }
                            if (this.roles.includes("tally")) {
                                window.location.href = "/tally/off-loading";
                            }
                            if (this.roles.includes("head-driver")) {
                                window.location.href = "/assignment/driver";
                            }
                            if (this.roles.includes("driver")) {
                                window.location.href = "/pickup-driver";
                            }
                            if (
                                this.roles.includes("crm-specialist-sales") ||
                                this.roles.includes("crm-specialist-cs") ||
                                this.roles.includes(
                                    "crm-specialist-telemarketer"
                                )
                            ) {
                                window.location.href = "/data/leads";
                            }
                            if (this.roles.includes("cashier")) {
                                window.location.href = "/order/cashier";
                            }
                            if (this.roles.includes("tracking")) {
                                window.location.href = "/tracking";
                            }
                        }, 2000);
                        // await this.postNotif();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTier() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/tier`, {
                headers: {
                    Accept: "application/json",
                },
            })
                .then((res) => {
                    localStorage.setItem("tier", res.data.data);
                    this.tier = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getUserRedirect() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/auth/account`, {
                headers: {
                    Authorization: "Bearer " + this.jwtToken,
                },
            })
                .then((res) => {
                    this.roles_go = res.data.data.roles;
                    this.checkLogin();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        parseJwt(token) {
            const [header, payload, signature] = token.split(".");

            try {
                const decodedHeader = atob(header);
                const decodedPayload = atob(payload);

                const parsedHeader = JSON.parse(decodedHeader);
                const parsedPayload = JSON.parse(decodedPayload);

                const expirationTime = parsedPayload.exp;

                this.parsedJwt = {
                    header: parsedHeader,
                    payload: parsedPayload,
                    signature: signature,
                    expirationTime: expirationTime,
                };

                this.getUserRedirect();
            } catch (error) {
                console.error("Error parsing JWT:", error);
                this.parsedJwt = null;
            }
        },
        checkLogin() {
            console.log("this.parsedJwt", [this.parsedJwt, this.roles_go]);
            var currentTimestamp = Math.floor(Date.now() / 1000);
            if (this.parsedJwt.payload.exp > currentTimestamp) {
                if (this.roles_go.includes("crm-manager")) {
                    this.$router.push("/dashboard");
                    return;
                }
                // if (this.roles_go.includes("crm-manager")) {
                //     this.$router.push("/crm/dashboard");
                //     return;
                // }
                // if (this.roles_go.includes("crm-specialist")) {
                //     this.$router.push("/crm/dashboard/specialist");
                //     return;
                // }
                // if (
                //     this.roles_go.includes("admin-super") ||
                //     this.roles_go.includes("admin-crm")
                // ) {
                //     console.log("crmdash");
                //     return;
                // }
            } else {
                this.$router.push("/");
            }
        },
    },
};
</script>

<style scoped>
.form-login {
    padding: 0.6rem 0.9rem;
    font-size: 15px;
    border-radius: 6px;
    border: 2px solid #f7f7f7;
}
.search-icon {
    top: 31px;
    font-size: 17px;
    right: 16px;
}
.pull-right-login {
    float: right;
}
.text-logo {
    color: #1291ff;
}
.map-login {
    width: 100%;
    position: absolute;
    bottom: 0;
    opacity: 0.1;
    z-index: 0;
}
.map-poin-login {
    width: 60%;
    bottom: 0;
    opacity: 0.2;
    z-index: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
}
.form-control {
    padding: 8px 12px !important;
}
.bc-login {
    background-color: #f3f5f7;
}
</style>
