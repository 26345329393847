<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <notifications group="foo" position="top right" />
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Pesanan</h3>
            </div>
            <div class="d-flex align-items-center no-flex mt-4">
                <div class="me-4 m-mx-0 m-mt-2">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="ms-auto">
                    <a href="/walk-in/create" class="btn btn-primary px-3">
                        <i class="pi pi-plus me-1 size-12"></i>
                        Buat Pesanan
                    </a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <div>
                    Menampilkan
                    <span class="fw-bold">1-{{ limit }}</span> Data dari
                    <span class="fw-bold">{{ lists.list_data.length }}</span>
                    Data
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select"
                            v-model="limit"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Kota Tujuan
                                </th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <a
                                        href="javascript:void(0)"
                                        @click="goToDetail(item)"
                                        class="text-link"
                                    >
                                        {{
                                            item.customer_name
                                                ? item.customer_name
                                                : "-"
                                        }}
                                    </a>
                                </td>
                                <td>{{ item.origin ? item.origin : "-" }}</td>
                                <td>
                                    {{ item.dest ? item.dest : "-" }}
                                </td>
                                <td class="text-center">
                                    <span class="tag mb-0 pb-0 px-3 radius">
                                        {{ item.status }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0)"
                                        @click="goToEdit(item)"
                                    >
                                        <img
                                            src="../assets/dashboard/edit-icon.png"
                                            width="20"
                                        />
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        class="ms-2"
                                        @click="showModalDelete(item)"
                                    >
                                        <img
                                            src="../assets/dashboard/trash-icon.png"
                                            width="20"
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar pesanan
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data Pesanan Akan Dihapus</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Pesanan Berhasil Dihapus
                    </h4>
                </div>
                <div class="mt-4">
                    <a href="/walk-in" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        ListLoader,
    },
    name: "WalkIn",
    data() {
        return {
            search: "",
            limit: 10,
            totalPage: 0,
            currentPage: 0,
            lists: {
                list_data: [],
            },
            origin: null,
            origins: [],
            destination: null,
            destinations: [],
            list_loading: false,
            is_loading_data: false,
            modalDelete: false,
            modalSuccess: false,
            is_save: false,
        };
    },
    mounted() {
        this.getLists();
        this.getRegency();
    },
    methods: {
        showModalDelete(item) {
            this.itemToDelete = item;
            this.modalDelete = true;
        },
        deleteItem() {
            this.is_save = true;
            const payload = {
                order_id: this.itemToDelete.order_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/delete`,
                payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_save = false;
                    this.modalDelete = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_save = false;
                    this.$toast.error(err.response.data.message, {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                    this.is_request = false;
                    this.modalRequest = false;
                });
        },
        filterData() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order/walkin`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    this.list_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list_loading = false;
                });
        },
        goToDetail(item) {
            this.$router.push({
                name: "walk-in-detail",
                query: {
                    order_id: item.order_id,
                },
            });
        },
        goToEdit(item) {
            this.$router.push({
                name: "walk-in-edit",
                query: {
                    order_id: item.order_id,
                },
            });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.origins = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
