<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="goBackListDriver()"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10 mt-4">
                <div class="box">
                    <div class="d-flex align-items-center">
                        <h4 class="mb-3 fw-bold">Detail Order</h4>
                        <div class="ms-auto">
                            <span
                                class="tag mb-0 pb-0 px-3 radius"
                                v-bind:class="{
                                    'yellow-cro': detail.is_calculate == false,
                                    'green-cro': detail.is_calculate == true,
                                }"
                            >
                                <template v-if="detail.is_calculate == true">
                                    Sudah Diukur
                                </template>
                                <template v-else> Belum Diukur </template>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Nama Pengirim
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.customer_name
                                                ? detail.customer_name
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Kota Asal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.origin ? detail.origin : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-120 fw-medium">
                                        CRM Specialist
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.specialist
                                                ? detail.specialist
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="w-100 ps-0 fw-bold" cellpadding="10">
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">Muatan</td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.description
                                                ? detail.description
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr class="border-bottom">
                                    <td class="ps-0 w-120 fw-medium">
                                        Kota Tujuan
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.destination
                                                ? detail.destination
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="ps-0 w-120 fw-medium">
                                        Tanggal Deal
                                    </td>
                                    <td class="w-30">:</td>
                                    <td class="fw-bold">
                                        {{
                                            detail.deal_time
                                                ? detail.deal_time
                                                : "-"
                                        }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-if="!detail.is_calculate">
            <h4 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h4>
            <div class="table-responsive-custom">
                <table class="not-read-only table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th class="fw-medium">Koli</th>
                            <th class="fw-medium">
                                Deskripsi<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat (Kg)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Panjang (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Lebar (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Tinggi (Cm)<span class="text-danger">*</span>
                            </th>
                            <th class="fw-medium">
                                Berat Volume (Kg)<span class="text-danger"
                                    >*</span
                                >
                            </th>
                            <th class="fw-medium">
                                Kubikasi (Kg3)<span class="text-danger">*</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="padding-body"
                        v-for="(item, index) in items"
                        :key="index"
                    >
                        <tr>
                            <td class="custom">
                                <money
                                    v-model="item.item"
                                    v-bind="format_number"
                                    class="form-control w-koli"
                                    placeholder="Koli"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <input
                                    type="text"
                                    class="form-control w-desc"
                                    placeholder="Deskripsi"
                                    v-model="item.description"
                                />
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.weight"
                                    v-bind="format_number"
                                    class="form-control"
                                    v-bind:class="{
                                        green: item.weight > item.volume,
                                    }"
                                    placeholder="Berat"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.length"
                                    v-bind="format_number"
                                    class="form-control"
                                    placeholder="P"
                                    @input="calculateResult()"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.width"
                                    v-bind="format_number"
                                    class="form-control"
                                    placeholder="L"
                                    @input="calculateResult()"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.height"
                                    v-bind="format_number"
                                    class="form-control"
                                    placeholder="T"
                                    @input="calculateResult()"
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <money
                                    v-model="item.volume"
                                    v-bind="format_number"
                                    class="form-control"
                                    v-bind:class="{
                                        green: item.volume > item.weight,
                                    }"
                                    disabled
                                >
                                </money>
                            </td>
                            <td class="custom">
                                <div class="d-flex align-items-center">
                                    <money
                                        v-model="item.cubic"
                                        v-bind="format_number"
                                        class="form-control me-2"
                                        disabled
                                    >
                                    </money>
                                    <a
                                        href="javascript:void(0)"
                                        @click="showModalDeleteItem(index)"
                                    >
                                        <img
                                            src="../assets/trash-yellow.png"
                                            width="16"
                                            lt=""
                                        />
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="custom" colspan="8">
                                <div class="w-300">
                                    <label class="fw-medium mb-1">
                                        Perlindungan Barang
                                    </label>
                                    <a
                                        href="javascript:void(0)"
                                        class="dropdown-toggle text-gray-dashboard w-"
                                        :id="`handling` + index"
                                        data-bs-toggle="dropdown"
                                        data-bs-auto-close="true"
                                        aria-expanded="false"
                                    >
                                        <div class="box radius-select">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div class="scroll-select">
                                                    <template
                                                        v-if="
                                                            item.handling
                                                                .length == 0
                                                        "
                                                    >
                                                        Pilih perlindungan
                                                        barang
                                                    </template>
                                                    <template v-else>
                                                        <span
                                                            v-for="(
                                                                handling_text,
                                                                index
                                                            ) in item.handling"
                                                            :key="index"
                                                            class="capitalize"
                                                        >
                                                            {{
                                                                handling_text
                                                                    .split("_")
                                                                    .join(" ")
                                                            }}<span
                                                                v-if="
                                                                    index !==
                                                                    item
                                                                        .handling
                                                                        .length -
                                                                        1
                                                                "
                                                                >,</span
                                                            >
                                                        </span>
                                                    </template>
                                                </div>
                                                <div class="ms-auto">
                                                    <img
                                                        src="../assets/angle-down.png"
                                                        class="ms-1"
                                                        width="14"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <ul
                                        class="dropdown-menu"
                                        :aria-labelledby="`handling` + index"
                                    >
                                        <li
                                            v-for="(
                                                handling, index
                                            ) in handlings"
                                            :key="index"
                                            class="pt-1 pb-1"
                                            v-bind:class="{
                                                'border-bottom':
                                                    index !==
                                                    handlings.length - 1,
                                            }"
                                        >
                                            <div
                                                class="form-check form-check-inline cursor-pointer"
                                            >
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    :id="handling.id"
                                                    :value="handling.value"
                                                    v-model="item.handling"
                                                />
                                                <label class="form-check-label">
                                                    {{ handling.name }}
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <button class="btn btn-green px-4">
                    <div class="d-flex align-items-center" @click="addItems()">
                        <i class="pi pi-plus me-1"></i>
                        Tambah Barang
                    </div>
                </button>
            </div>
            <hr class="mt-4" />
            <h4 class="fw-bold mb-0">Hasil</h4>
            <div class="table-responsive-custom">
                <table class="result not-read-only table table-bordered mt-3">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="fw-medium">
                                Berat Aktual (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Berat Volume (Kg)
                            </th>
                            <th scope="col" class="fw-medium">
                                Kubikasi (Kg3)
                            </th>
                            <th scope="col" class="fw-medium">
                                Charge Weight (Kg)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalWeight) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalVolume) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box">
                                    {{ decimalFormat(totalCubic) }}
                                </div>
                            </td>
                            <td>
                                <div class="form-box green">
                                    {{ decimalFormat(chargeWeight) }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex">
                <div class="ms-auto">
                    <button
                        class="btn btn-primary px-5 btn-lg"
                        @click="modalSuccessConfirmation = true"
                        :disabled="
                            totalWeight == 0 ||
                            chargeWeight == 0 ||
                            items[0].description == ''
                        "
                    >
                        Simpan Data
                    </button>
                </div>
            </div>
        </div>
        <div class="box mt-4" v-else>
            <h4 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h4>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jumlah Koli</th>
                            <th class="text-center" scope="col">Deskripsi</th>
                            <th class="text-center" scope="col">
                                Dimensi Barang (Cm)
                            </th>
                            <th class="text-center" scope="col">Berat (Kg)</th>
                            <th class="text-center" scope="col">
                                Berat Volume (Kg)
                            </th>
                            <th class="text-center" scope="col">
                                Kubikasi (Kg3)
                            </th>
                            <th class="text-center" scope="col">
                                Perlindungan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in detail.items" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ item.qty }}</td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">
                                {{ item.width }} Cm X {{ item.length }} Cm X
                                {{ item.height }} Cm
                            </td>
                            <td
                                :class="{
                                    'bc-green':
                                        customRound(item.weight) >
                                        customRound(item.weight_volume),
                                    'text-center': true,
                                }"
                            >
                                {{ customRound(item.weight) }} Kg
                            </td>
                            <td
                                :class="{
                                    'bc-green':
                                        customRound(item.weight_volume) >
                                        customRound(item.weight),
                                    'text-center': true,
                                }"
                            >
                                {{ customRound(item.weight_volume) }} Kg
                            </td>
                            <td class="text-center">
                                {{ customRound(item.cubic) }} Kg3
                            </td>
                            <td class="capitalize">
                                <div v-if="item.packing">
                                    <div
                                        v-for="(
                                            pack, index
                                        ) in item.packing.split(',')"
                                        :key="index"
                                    >
                                        <span class="tag gray-new radius pb-0">
                                            {{ pack.replace(/_/g, " ") }}
                                        </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <span>-</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">Total</div>
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalWeightDefault) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalVolumeDefault) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalCubicDefault) }} Kg3
                            </td>
                            <td class="p-footer"></td>
                        </tr>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">
                                    Charge Weight (Kg)
                                </div>
                            </td>
                            <td
                                class="text-center bc-green p-footer"
                                colspan="4"
                            >
                                {{ customRound(chargeWeightDefault) }} Kg
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data Ini Akan Dihapus</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ukur-timbang
                        barang ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessConfirmation"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessConfirmation">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Ukur-Timbang Barang Akan Disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data orderan dan driver yang dipilih sudah
                        benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalSuccessConfirmation = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="storeData()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Ukur-Timbang Barang Berhasil Disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="goBackListDriver()"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../api/Api";
import { Money } from "v-money";

export default {
    components: {
        // Multiselect,
        Money,
    },
    name: "TallyDetail",
    data() {
        return {
            handlings: [
                {
                    name: "Karung Kecil",
                    value: "karung_kecil",
                    id: 1,
                },
                {
                    name: "Karung Sedang",
                    value: "karung_besar",
                    id: 2,
                },
                {
                    name: "Plastik",
                    value: "plastik",
                    id: 3,
                },
                {
                    name: "Bubble Wrap",
                    value: "bubble_wrap",
                    id: 4,
                },
                {
                    name: "Kayu",
                    value: "kayu",
                    id: 5,
                },
            ],
            items: [
                {
                    item: 1,
                    description: "",
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    volume: 0,
                    cubic: 0,
                    handling: [],
                },
            ],
            format_number: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
            },
            delete_value: null,
            modalDelete: false,
            detail: {},
            is_loading_data: false,
            is_save: false,
            modalSuccessConfirmation: false,
            modalSuccess: false,
        };
    },
    computed: {
        totalWeightDefault() {
            return this.detail.items.reduce(
                (total, item) => total + this.customRound(Number(item.weight)),
                0
            );
        },
        totalVolumeDefault() {
            return this.detail.items.reduce(
                (total, item) =>
                    total + this.customRound(Number(item.weight_volume)),
                0
            );
        },
        totalCubicDefault() {
            return this.detail.items.reduce(
                (total, item) => total + this.customRound(Number(item.cubic)),
                0
            );
        },
        chargeWeightDefault() {
            return this.detail.items.reduce((total, item) => {
                return (
                    total +
                    Math.max(
                        this.customRound(Number(item.weight)),
                        this.customRound(Number(item.weight_volume))
                    )
                );
            }, 0);
        },
        totalWeight() {
            return this.items.reduce(
                (total, item) => total + item.item * Math.round(item.weight),
                0
            );
        },
        totalVolume() {
            return this.items.reduce(
                (total, item) => total + item.item * Math.round(item.volume),
                0
            );
        },
        totalCubic() {
            return this.items.reduce(
                (total, item) => total + item.item * Math.round(item.cubic),
                0
            );
        },
        chargeWeight() {
            return this.items.reduce((total, item) => {
                const itemWeight = item.item * Math.round(item.weight);
                const itemVolume = item.item * Math.round(item.volume);
                return total + Math.max(itemWeight, itemVolume);
            }, 0);
        },
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        goBackListDriver() {
            this.$router.push({
                name: "tally-driver",
                query: {
                    driver_fleet_id: this.$route.query.driver_fleet_id,
                    date_offload: this.$route.query.date_offload,
                },
            });
        },
        addItems() {
            this.items.push({
                item: 1,
                description: "",
                weight: 0,
                length: 0,
                width: 0,
                height: 0,
                volume: 0,
                cubic: 0,
                handling: [],
            });
        },
        showModalDeleteItem(index) {
            this.modalDelete = true;
            this.delete_value = index;
        },
        deleteItem() {
            this.items.splice(this.delete_value, 1);
            this.modalDelete = false;
        },
        calculateResult() {
            this.items.forEach((val) => {
                if (val.length !== 0 && val.width !== 0 && val.height !== 0) {
                    val.volume = (val.length * val.width * val.height) / 4000;
                    val.cubic = (val.length * val.width * val.height) / 1000000;
                } else {
                    val.volume = 0;
                    val.cubic = 0;
                }
            });
            // this.calculateMaxValue();
        },
        getDetail() {
            const param = this.$route.query;
            this.is_loading_data = true;
            var data = {
                delivery_assignation_id: param.delivery_assignation_id,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/calculate/item/detail`,
                {
                    params: data,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                    this.is_loading_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_loading_data = false;
                });
        },
        storeData() {
            const param = this.$route.query;
            this.is_save = true;
            const dataToSend = this.items.map((item) => ({
                delivery_assignation_id: param.delivery_assignation_id,
                qty: item.item,
                description: item.description,
                weight: item.weight,
                length: item.length,
                width: item.width,
                height: item.height,
                weight_volume: item.volume,
                cubic: item.cubic,
                protection: item.handling.join(","),
            }));
            var data = dataToSend;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/calculate/submit`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.is_save = false;
                    this.modalSuccessConfirmation = false;
                    this.modalSuccess = true;
                    console.log(res);
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
    },
};
</script>

<style scoped>
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.not-read-only.table > :not(caption) > * > * {
    border: unset;
}
.not-read-only.table-bordered > :not(caption) > * {
    border: unset;
}
.not-read-only.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
</style>
